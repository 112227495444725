import LazyLoad from "vanilla-lazyload";
import PhotoSwipeLightbox from 'photoswipe/lightbox';

// --------------------------------------------------
// 💤 Lazy Loading
// --------------------------------------------------

var lazyLoadInstance = new LazyLoad({
  // Your custom settings go here
  elements_selector: "[data-lazy]"
});

//////
////// Dropdown button
//////

var btnEl = document.querySelector('.dropdown-button');
if (btnEl) {

var btnEl = document.querySelector('.dropdown-button');

/* When the user clicks on the button, 
toggle between hiding and showing the dropdown content */

function toggleButton() {
  document.getElementById("menuButton").classList.toggle("show");
  document.getElementById("clickableMenuButton").classList.toggle("menu-open");
}

// Close the dropdown if the user clicks outside of it
window.onclick = function(event) {
  if (!event.target.matches('.dropdown-button')) {
    // Add/remove class to drop down
    var dropdowns = document.getElementsByClassName("dropdown-content");
    var i;
    for (i = 0; i < dropdowns.length; i++) {
      var openDropdown = dropdowns[i];
      if (openDropdown.classList.contains('show')) {
        openDropdown.classList.remove('show');
      }
    }
    var dropdownsButton = document.getElementById("clickableMenuButton")
    if (dropdownsButton.classList.contains('menu-open')) {
      dropdownsButton.classList.remove('menu-open');
    }
  }
}

btnEl.addEventListener('click', toggleButton);

}

//////
////// Image gallery for films
//////

const lightbox = new PhotoSwipeLightbox({
  gallery: '#gallery',
  children: 'a',
  showHideAnimationType: 'none',
  mainClass: 'pswp--custom-icon-colors',
  pswpModule: () => import('photoswipe')
});
lightbox.init();

//////
////// Ajax form
//////

var form = document.querySelector('.form__contact');
if (form) {

// Message form Ajax
window.addEventListener('load', function () {
  var form = document.querySelector('.form__contact');
  var message = document.getElementById('message');
  var fields = {};
  form.querySelectorAll('[name]').forEach(function (field) {
      fields[field.name] = field;
  });

  // Displays all error messages and adds 'error' classes to the form fields with
  // failed validation.
  var handleError = function (response) {
      var errors = [];
      for (var key in response) {
          if (!response.hasOwnProperty(key)) continue;
          if (fields.hasOwnProperty(key)) fields[key].classList.add('error');
          Array.prototype.push.apply(errors, response[key]);
      }
      message.innerHTML = errors.join('<br>');
  }

  var onload = function (e) {
      if (e.target.status === 200) {
        form.querySelectorAll('[name]').forEach(function (field) {
          field.value = "";
        });
          message.innerHTML = 'Gracias!'
      } else {
          handleError(JSON.parse(e.target.response));
      }
  };

  var submit = function (e) {
      e.preventDefault();
      var request = new XMLHttpRequest();
      request.open('POST', e.target.action);
      request.onload = onload;
      request.send(new FormData(e.target));
      // Remove all 'error' classes of a possible previously failed validation.
      for (var key in fields) {
          if (!fields.hasOwnProperty(key)) continue;
          fields[key].classList.remove('error');
      }
  };
  form.addEventListener('submit', submit);
});

}

//////
////// Plyr videos
//////

import Plyr from 'plyr';

// Change the second argument to your options:
// https://github.com/sampotts/plyr/#options
// const testPlayer = new Plyr('#testPlayer', {captions: {active: true}});

// // Expose player so it can be used from the console
// window.testPlayer = testPlayer;

var player = document.getElementById("player");
if (player) {
// Change the second argument to your options:
// https://github.com/sampotts/plyr/#options
const player = new Plyr('#player', {
  controls: ['play', 'progress', 'current-time', 'pip', 'airplay', 'fullscreen'],
  hideControls: true,
  tooltips: { controls: false, seek: false },
  fullscreen: { enabled: true, fallback: true, iosNative: true, container: null },
  vimeo: { playsinline: false }
});

// Hide controls
player.on('controlshidden', (event) => {
  document.getElementById("headerWrapper").classList.toggle("hide-header");
  document.getElementById("videoOverlay").classList.toggle("hide-header");
  // Add/remove class to drop down
  var dropdownsOne = document.getElementsByClassName("dropdown-content");
  var i;
  for (i = 0; i < dropdownsOne.length; i++) {
    var openDropdownOne = dropdownsOne[i];
    if (openDropdownOne.classList.contains('show')) {
      openDropdownOne.classList.remove('show');
    }
  }
  var dropdownsButton = document.getElementById("clickableMenuButton")
  if (dropdownsButton.classList.contains('menu-open')) {
    dropdownsButton.classList.remove('menu-open');
  }
  document.body.classList.toggle("hide-cursor");
});

// Show controls
player.on('controlsshown', (event) => {
  document.getElementById("headerWrapper").classList.toggle("hide-header");
  document.getElementById("videoOverlay").classList.toggle("hide-header");
  document.body.classList.toggle("hide-cursor");
});

var videoOverlay = document.getElementById("videoOverlay");
videoOverlay.addEventListener("mouseover", function(e) {
  setTimeout(() => {
    if (document.querySelector('.plyr').classList.contains('plyr--hide-controls')) {
      console.log('controls hidden');
      player.toggleControls(true);
    }
    else {
      console.log('controls not hidden')
    }
  }, 150);
});

// View film button
var textPlayButton = document.getElementById("textPlay");
textPlayButton.addEventListener("click", function(e) {
player.play();
setTimeout(() => {
  if (document.querySelector('.plyr').classList.contains('plyr--hide-controls')) {
    console.log('controls hidden');
  }
  else {
    console.log('controls not hidden')
    player.toggleControls(false);
  }
}, 160);
});

var textPauseButton = document.getElementById("textPause");
textPauseButton.addEventListener("click", function(e) {
  player.pause()
});

// Change play button text etc
player.on('play', (event) => {
  textPlayButton.style.display = 'none';
  textPauseButton.style.display = 'block';
});

player.on('pause', (event) => {
  textPauseButton.style.display = 'none';
  textPlayButton.style.display = 'block';
});

// Info overlay
var infoShowButton = document.getElementById("infoShow");
var infoHideButton = document.getElementById("infoHide");
var infoContainer = document.getElementById("infoContainer");

infoShowButton.addEventListener("click", function(e) {
console.log('playerpaused')
player.pause()
infoContainer.classList.toggle("show-info-container");
});

infoHideButton.addEventListener("click", function(e) {
  infoContainer.classList.toggle("show-info-container");
});

// Expose player so it can be used from the console
window.player = player;
}

//////
////// Preview videos
//////

var backgroundVideo = document.getElementById("background-video");
if (backgroundVideo) {

var videoPlayer = function() {
  "use strict";

  // Private variables
  var _currentTrack = null;
  var _elements = {
    video: document.getElementById("background-video"),
    playListRows: document.getElementsByClassName("play-list-row")
  };
  var _trackLoaded = false;

  /**
   * Initializes the html5 video player and the playlist.
   *
   **/
  var initPlayer = function() {

    //Adding event listeners to playlist clickable elements.
    for (var i = 0; i < _elements.playListRows.length; i++) {
      var playListLink = _elements.playListRows[i];

      if (window.innerWidth < 600) {
        // Automatic video on mobile
        backgroundVideo.preload = "auto";
        backgroundVideo.autoplay = true;
        backgroundVideo.playsInline = true;
        var selectedTrack = '1';
        _currentTrack = parseInt(selectedTrack);
        _setTrack();
      } else {
        //Playlist link clicked.
        playListLink.addEventListener("mouseenter", function(e) {
          e.preventDefault();
          var selectedTrack = parseInt(this.getAttribute("data-track-row"));

          if (selectedTrack !== _currentTrack) {
            _currentTrack = null;
            _trackLoaded = false;
          }

          if (_trackLoaded === false) {
            _currentTrack = parseInt(selectedTrack);
            _setTrack();
          } else {
            _playBack(this);
          }
        }, false);
      }
    }

    //video track has ended playing.
    _elements.video.addEventListener("ended", function(e) {
      _trackHasEnded();
    }, false);

  };

  /**
   * Controls playback of the video element.
   *
   **/
  var _playBack = function() {
    _elements.video.play();
  };

  /**
   * Sets the track if it hasn't already been loaded yet.
   *
   **/
  var _setTrack = function() {
    var songURL = _elements.video.children[_currentTrack - 1].src;
    _elements.video.setAttribute("src", songURL);
    _elements.video.load();
    _trackLoaded = true;
    _setActiveItem(_currentTrack, _elements.playListRows);
    _playBack();
    _elements.video.style.display = "block";
  };

  /**
   * Sets the activly playing item within the playlist.
   *
   * @param currentTrack The current track number being played.
   * @param playListRows The playlist object.
   **/
  var _setActiveItem = function(currentTrack, playListRows) {
    for (var i = 0; i < playListRows.length; i++) {
      playListRows[i].className = "play-list-row video-still";
    }
    playListRows[currentTrack - 1].className = "play-list-row video-still active-track";
  };

  /**
   * Plays the next track when a track has ended playing.
   *
   **/
  var _trackHasEnded = function() {
    parseInt(_currentTrack);
    _currentTrack = (_currentTrack === _elements.playListRows.length) ? 1 : _currentTrack + 1;
    _trackLoaded = false;
    _setTrack();
  };

  return {
    initPlayer: initPlayer
  };
};

(function() {
  var player = new videoPlayer();

  player.initPlayer();
})();

}
